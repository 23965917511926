<template>
    <div class="app-search-box" :type="type">
        <app-loader v-if="loading" />

        <app-textarea v-if="type == 'textarea'" v-model="word" :label="label" :disabled="is_disabled"                                                                                          />
        <app-input    v-else                    v-model="word" :label="label" :disabled="is_disabled" :icon="icon" :show-clear-button="showClearButton" @input="onChange" @onenter="search" @clear="search" />

        <button v-if="show_search_button" class="btn btn-primary" :disabled="is_disabled" @click="search">{{ buttonText }}</button>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'

export default {
    props: {
        value:           { type: String,  default: ''       },

        label:           { type: String,  default: null     },
        buttonText:      { type: String,  default: 'Search' },

        type:            { type: String,  default: 'input'   },

        loading:         { type: Boolean, default: false    },
        disabled:        { type: Boolean, default: false    },

        debounce:        { type: Number,  default: 0        }, // The number of milliseconds to wait

        showClearButton: { type: Boolean, default: false    },

        icon: { type: String, default: null },
    },

    components: {
        appLoader,
        appInput,
        appTextarea,
    },

    data() {
        return {
            word_value: '',

            debounce_timeout: null,
        }
    },

    methods: {
        onChange() {
            if (this.debounce) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = setTimeout(() => {
                    this.search()
                }, this.debounce)
            }
        },

        search() {
            this.$emit('search', this.word)

            if (this.debounce_timeout !== null) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = null
            }
        },

        reset() {
            this.word = ''
        },
    },

    computed: {
        word: {
            get() {
                return this.value || this.word_value
            },

            set(value) {
                this.word_value = value

                this.$emit('input', value)
                this.$emit('change', value)
            },
        },

        is_disabled() {
            return this.loading || this.disabled
        },

        show_search_button() {
            return this.debounce == 0
        },
    },
}
</script>

<style lang="scss">
.app-search-box {
    position: relative;

    display: flex;

    padding: 24px;

    background-color: var(--color-component-bg-primary);
    border-radius: $border-radius-secondary;
    box-shadow: var(--box-shadow-primary);

    .app-input {
        margin: 0 30px 0 0;

        &:last-child {
            margin: 0;
        }
    }

    .app-textarea {
        margin: 0 0 30px 0;
    }

    .btn {
        max-width: 170px;
    }

    &[type=textarea] {
        flex-direction: column;

        .btn {
            margin-left: auto;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-search-box {
        padding: 24px 8px;
    }
}

@media (max-width: $mobile-size) {
    .app-search-box {
        flex-direction: column;

        padding: 16px 8px;

        .app-input,
        .app-textarea {
            margin: 0 0 24px 0;
        }

        .btn {
            max-width: none;
        }
    }
}
</style>